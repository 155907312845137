import type { Sub } from "~/types/subscriptions";

import { waitForKC } from "~/composables/keycloak";
import { waitForPB } from "~/composables/pb";
import { waitForSubscription } from "~/composables/subscription";

export async function waitAuth (subscriptionType: Sub.Type | undefined = undefined): Promise<any> {
    await waitForKC();
    const { isAuthenticated } = useKeycloak();

    await waitForPB();
    if (subscriptionType) {
        await waitForSubscription(subscriptionType);
    }

    if (!isAuthenticated.value) {
        return navigateTo("/");
    }
}
